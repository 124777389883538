import React, { Component } from "react";
import data from '../data.json';
import './coran-style.css';
import { Link } from "react-router-dom";

const surat_data = data;

export default class SummaryPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      term: ''
    }
  }

  filterSummary(term) {
    return surat_data
    .filter((data) => data.title.toLowerCase().includes(term.toLowerCase()) || data.titleAr.includes(term))
    .map( (filteredData, index) => {
      return (
        <Link key={index} to={"/"+filteredData.pages} className="summary-link">
          <span className="pull-left w-3">{filteredData.title}</span>
          <span className="w-3">{filteredData.index}</span>
          <span className="w-3">{filteredData.pages}</span>
          <span className="pull-right w-3">{filteredData.titleAr}</span>
        </Link>
      );
    });
  }

  renderSummary(term) {
    return surat_data.map( (data, index) =>{
        return (
          <Link key={index} to={"/"+data.pages} className="summary-link">
            <span className="pull-left w-3">{data.title}</span>
            <span className="w-3">{data.index}</span>
            <span className="w-3">{data.pages}</span>
            <span className="pull-right w-3">{data.titleAr}</span>
          </Link>
        );
    });
  }

  handleInputChange(event) {
    this.setState({term: event.target.value});
  }

  render() {
    return (
      <div className="summary-page">

        <div className="searchBar">
          <input placeholder="Rechercher une Surah" onChange={this.handleInputChange.bind(this)} />
        </div>
        <div className="summary-header">
          <span className="pull-left w-3">Surah</span>
          <span className="w-3">Numéro</span>
          <span className="w-3">Page</span>
          <span className="pull-right w-3">سورة</span>
        </div>
        {this.filterSummary(this.state.term)}

      </div>
    )
  }
}