import React from 'react';
import './App.css';
import CoranPage from './CoranComponents/CoranPage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SummaryPage from './CoranComponents/SummaryPage';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={SummaryPage} />
        <Route path="/:page" component={CoranPage} />
      </Switch>
    </BrowserRouter>
  );
}

/* <div className="App">
      <CoranPage page="3"/>
    </div> */

export default App;
