import React, { Component } from "react";
import data from '../data.json';
import './coran-style.css';
import { Link } from "react-router-dom";

const surat_data = data;

export default class CoranPage extends Component {

  getData(page) {
    for (var i = 0; i < surat_data.length; i++){
      if (surat_data[i].pages <= page && surat_data[i+1].pages > page){
        return surat_data[i];
      }
    }
    return null;
  }
  
  renderHeader(data) {
    return (
      <div className="top">
        <span className="pull-left w-4">{data.title}</span>
        <span className="text-center w-4"><Link to={"/"} className="">Accueil</Link></span>
        <span className="pull-right w-4">{data.titleAr}</span>
        {/* <span className="pull-right">Juz' {data.juz.index}</span> */}
      </div>
    );
  }

  renderFooter(page) {
    let buttonNext = <span className="w-4"></span>;
    let buttonPrev = <span className="w-4"></span>;
    /* if(page === 1) {
      buttonPrev = <Link className="pull-left w-4" to={"/"}>Accueil</Link>;
    } */
    if(page < 604) {
      buttonNext = <Link className="pull-left w-4" to={"/"+(page+1)}>Suiv.</Link>;
    }
    if(page > 1) {
      buttonPrev = <Link className="pull-right w-4" to={"/"+(page-1)}>Préc.</Link>;
    }
    
    return (
      <div className="pull-bottom">
        {buttonNext}
        <span className="text-center w-4">{page?page:''}</span>
        {buttonPrev}
      </div>
    );
  }

  render() {
    let page = this.props.match.params.page;
    page = page>0 && page<605 ? page : '1';
    let pad = "000";
    let img = pad.substring(0, pad.length - page.length) + page;
    img = "/img/page"+img+".png";
    
    page = parseInt(page);

    // let data = this.getData(page);
    let data = this.getData(page);
    

    return (
      <div key={page} className="coran-page">

        
        {data ? this.renderHeader(data) : ''}

        <img src={img} alt="" className={page<3 ? "img-fluid" : "img-fluid-reverse"} />

        {this.renderFooter(page)}

      </div>
    )
  }
}